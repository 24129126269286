@import '~@idexx/spot/dist/styles/shared/_spot_tokens.scss';

.show-skeletons {
  .skeleton:not(.skeleton-exception) {
    background: $spot-color-neutral-91 !important;
    cursor: not-allowed !important;
    border-radius: 0.25rem !important;
    color: transparent;

    > *:not(.skeleton-exception) {
      visibility: hidden;
    }
  }
}

.base-layout {
  .base-layout-content {
    margin-top: 130px;
    margin-bottom: 80px;
    width: 100%;
    position: relative;

    .base-layout--left {
      float: left;
      width: 275px;
      margin-left: 5px;
    }

    .base-layout--right {
      width: calc(100% - 285px);
      box-sizing: border-box;
      overflow: auto;
      padding: 0 20px 20px 20px;
    }
  }
}
