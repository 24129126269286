@import '~@idexx/spot/dist/styles/shared/_spot_tokens.scss';
@import '~@idexx/spot/dist/styles/shared/typography.scss';
@import '../../styles/common/typography.scss';

.manage-breeds_wrapper {
  .page-heading {
    .heading--level-1 {
      @include spot-typography__heading--level-1;
      position: initial;
      width: auto;
      top: -5px;
      white-space: nowrap;
    }

    .spot-typography__text--tertiary {
      @include spot-typography__text--tertiary;
      margin-bottom: 20px;
    }
  }

  .spot-search-bar {
    max-width: 480px;
    margin-top: 32px;

    .spot-search-bar__search-button[disabled] {
      cursor: not-allowed;
      pointer-events: all !important;

      svg {
        cursor: inherit;
        fill: $spot-color-neutral-74;
      }
    }
  }


  #breeds-management-grid {
    margin-top: 32px;

    & > colgroup > .spot-data-table__col--index-0 {
      max-width: 30px;
    }

    & > colgroup > .spot-data-table__col--index-4 {
      max-width: 220px;
    }

    & > tbody > tr > td:nth-child(5) {
      text-align: right;
    }

    .expand-collapse-icon {
      width: 16px;
      height: 16px;
      fill: $spot-color-teal-35;
      cursor: pointer;
    }

    .subrow {
      .subrow-wrapper {
        max-height: 520px;
        margin: 0 0 4px 1%;
        overflow-y: auto;
        text-align: right;

        .subrow-info-wrapper {
          display: flex;
          justify-content: space-between;
          margin-top: 6px;
          margin-right: 10px;

          .displaying-label {
            justify-content: flex-start;
          }

          .breed-mapping-dropdown {
            justify-content: flex-end;
            margin: 4px 0 4px 0;
          }
        }

        .breeds-management-subrow-grid {
          margin-left: 0;

          & > colgroup > .spot-data-table__col--index-0 {
            width: 10%;
          }

          & > colgroup > .spot-data-table__col--index-1 {
            width: 30%;
          }

          td {
            padding-top: 1px;
            padding-bottom: 0;
          }
        }
      }
    }
  }

  .manage-breeds_actions {
    display: flex;
    margin: 60px 20px 0 20px;
    justify-content: space-between;

    .back {
      justify-content: flex-start;
    }

    .main-buttons {
      justify-content: flex-end;

      .spot-button {
        margin-left: 20px;
      }
    }
  }
}

