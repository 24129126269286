@import '~@idexx/spot/dist/styles/shared/_spot_tokens.scss';

.side-menu {
  background: $spot-interactive-color-on-light;
  fill: white;
  margin-left: 1px;
  padding: 13px 10px 0 12px;

  .menu-nav-link {
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    margin-left: 34px;
    color: white;

    a {
      cursor: pointer;
      color: white;
    }

    &.not-allowed a {
      cursor: not-allowed;
    }

    &.active a {
      font-weight: bold;
      color: white;
    }

    &.subheader {
      font-size: 18px;
      margin: 5px;
    }

    .spot-icon {
      margin-right: 6px;
    }
  }

  .empty-space {
    height: 15px;
  }
}
