@import '~@idexx/spot/dist/styles/shared/_spot_tokens.scss';
@import '~@idexx/spot/dist/styles/shared/typography.scss';
@import '../../styles/common/typography.scss';

.offer-setup_wrapper {
  .page-heading {
    .heading--level-1 {
      @include spot-typography__heading--level-1;
      position: initial;
      width: auto;
      top: -5px;
      white-space: nowrap;
    }

    .spot-typography__text--tertiary {
      @include spot-typography__text--tertiary;
      margin-bottom: 20px;
    }
  }

  .refresh-practices-btn {
    margin-top: $spot-space-inset-one-and-a-half;
  }

  .block-spinner {
    margin-top: 10px;
    display: block;

    span {
      font-size: 13px;
    }
  }

  #practices-grid {
    margin-left: 0;
    margin-top: $spot-space-inset-one-and-a-half;
    font-size: 14px;

    td {
      .spot-form__radio-group {
        margin-bottom: 0;

        .automated-popups-editors {
          margin-top: 4px;
          margin-bottom: 0;
        }
      }

      .spot-form__checkbox-group {
        margin-bottom: 0;

        .manual-buttons-editors {
          margin-top: 4px;
          margin-bottom: 0;
        }
      }
    }
  }

  .offer-setup_actions {
    display: flex;
    margin: 150px 20px 0 20px;
    justify-content: space-between;

    .back {
      justify-content: flex-start;
    }

    .main-buttons {
      justify-content: flex-end;

      .spot-button {
        margin-left: 20px;
      }
    }
  }
}
