@import '~@idexx/spot/dist/styles/shared/_spot_tokens.scss';
@import '~@idexx/spot/dist/styles/shared/typography.scss';
@import '../../styles/common/typography.scss';

.manage-species_wrapper {
  .page-heading {
    .heading--level-1 {
      @include spot-typography__heading--level-1;
      position: initial;
      width: auto;
      top: -5px;
      white-space: nowrap;
    }

    .spot-typography__text--tertiary {
      @include spot-typography__text--tertiary;
      margin-bottom: 20px;
    }
  }
   .retrieve-species-and-breeds-btn {
     margin-top: $spot-space-inset-two;
   }

  .block-spinner {
    margin-top: 10px;
    display: block;

    span {
      font-size: 13px;
    }
  }

  #species-management-grid {
    max-width: 640px;
    margin-left: 0;
    margin-top: 10px;

    td {
      padding-top: 1px;
      padding-bottom: 0;
    }
  }

  .manage-species_actions {
    display: flex;
    margin: 60px 20px 0 20px;
    justify-content: space-between;

    .back {
      justify-content: flex-start;
    }

    .main-buttons {
      justify-content: flex-end;

      .spot-button {
        margin-left: 20px;
      }
    }
  }
}

