@import '~@idexx/spot/dist/styles/shared/_spot_tokens.scss';
@import '~@idexx/spot/dist/styles/shared/typography.scss';
@import '../../styles/common/typography.scss';

.connect-practice_wrapper {
  .page-heading {
    .heading--level-1 {
      @include spot-typography__heading--level-1;
      position: initial;
      width: auto;
      top: -5px;
      white-space: nowrap;
    }

    .spot-typography__text--tertiary {
      @include spot-typography__text--tertiary;
      margin-bottom: 20px;
    }
    
    .data-refresh-reminder {
      @include spot-typography__text--tertiary;
      margin-bottom: 5px;
    }
  }
   .retrieve-practices-btn {
     margin-top: $spot-space-inset-one-and-a-half;
   }

   .refresh-practices-btn {
     margin-top: $spot-space-inset-one-and-a-half;
   }

  .block-spinner {
    margin-top: 10px;
    display: block;

    span {
      font-size: 13px;
    }
  }

  #practices-grid {
    margin-left: 0;
    margin-top: $spot-space-inset-one-and-a-half;
    font-size: 14px;

    td {
      vertical-align: top;

      .spot-popover {
        font-size: 13px;
        width: 290px;
      }

      .spot-pill {
        margin-top: 3px;
        cursor: pointer;
      }
    }

    .subrow {
      .subrow-wrapper {
        max-height: 440px;
        margin: 0 0 4px 1%;
        overflow-y: auto;
        text-align: right;

        .subrow-info-wrapper {
          display: flex;
          justify-content: space-between;
          margin-top: 8px;

          .displaying-label {
            justify-content: flex-start;
          }

          .cancel-btn {
            justify-content: flex-end;
            margin-right: 8px;
          }
        }

        .connect-practice-subrow-grid {
          font-size: 14px;
          margin-top: 6px;
          margin-left: 0;

          td {
            padding-top: 1px;
            padding-bottom: 0;

            .practice-name-rb {
              margin-top: 2px;
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }

  .connect-practice_actions {
    display: flex;
    margin: 150px 20px 0 20px;
    justify-content: space-between;

    .back {
      justify-content: flex-start;
    }

    .main-buttons {
      justify-content: flex-end;

      .spot-button {
        margin-left: 20px;
      }
    }
  }
}

